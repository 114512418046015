.files_list {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  overflow-y: auto;
  margin: 0;
  padding: 0;
}

.files_list_item {
  width: calc(100% - 0.5rem);
  display: flex;
  align-items: center;
  justify-content: flex-start;
  padding: 0.5rem 0;
  display: grid;
  grid-template-columns: calc(100% - 1rem) 1rem;

  &:not(:last-child) {
    border-bottom: 1px solid #f4f4f4;
  }
}

.files_list_item_name {
  color: #0073cf;
  font-weight: 500;
  text-overflow: ellipsis;
  overflow: hidden;
  width: 100%;
  text-align: left;
}

.file_list_item_progress {
  width: 100%;
  margin-left: auto;
}

.file_list_item_check {
  width: 1rem;
  height: 1rem;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #008000;
}

.files_list_item_clear {
  width: 16px;
  height: 16px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;

  &:hover {
    color: #0073cf;
  }

  svg {
    width: 100%;
    height: 100%;
  }
}
