.wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
}

.banner_text {
  font-size: 1.4rem;
  color: #ccc;
  display: block;
  margin: 0.5rem;
}

.banner {
  background-color: #fafafa;
  width: 100%;
  min-width: 340px;
  border: 4px dashed #ccc;
  height: 240px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-bottom: 1rem;
  cursor: pointer;
}

.input {
  width: 100%;
  height: 100%;
  display: none;
}
