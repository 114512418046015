.wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 0 5px;
  margin-top: 16px;
  border-radius: 4px;
}

.canvas_wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
}

.files_list_wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
}

.files_list_progress_wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  margin: 1rem 0;
}

.upload_button {
  min-width: 100px;
  height: 40px;
  border: none;
  border-radius: 4px;
  background-color: #0073cf;
  color: #fff;
  font-size: 1rem;
  font-weight: 500;
  cursor: pointer;
  outline: none;
  white-space: nowrap;
  padding: 0.25rem 0.5rem;

  &.disabled {
    background-color: #ccc;
    color: #fff;
    cursor: not-allowed;
  }
}
